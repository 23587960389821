<template>
    <div class="all">
        <ul class="headBox">
            <!-- 用户数量 -->
            <li >
                <div class="top">
                    <span>用户数量</span>
                    <el-tag type="success">今日</el-tag>
                </div>
                <p class="number">{{info1.todayMemberNum}}</p>
                <div class="desc">
                    <span>昨日</span>
                    <span>{{info1.yestMemberNum}}</span>
                    <span>日环比</span>
                    <span>{{info1.rateOfMember}}</span>
                </div>
                <div class="bottom">
                    <div class="left">总公司</div>
                    <div class="right">{{info1.allMemberNum}}人</div>
                </div>
            </li>
          <!-- 发布市集数量 -->
          <li >
                <div class="top">
                    <span>发布市集数量</span>
                    <el-tag type="success">今日</el-tag>
                </div>
                <p class="number">{{info1.fairTodayNum}}</p>
                <div class="desc">
                    <span>昨日</span>
                    <span>{{info1.fairYestNum}}</span>
                    <span>日环比</span>
                    <span>{{info1.rateOfFair}}</span>
                </div>
                <div class="bottom">
                    <div class="left">总市集数量</div>
                    <div class="right">{{info1.allFairNum}}条</div>
                </div>
            </li>
            <!-- 短视频数量 -->
            <li >
                <div class="top">
                    <span>短视频数量</span>
                    <el-tag type="success">今日</el-tag>
                </div>
                <p class="number">{{info1.videoTodayNum}}</p>
                <div class="desc">
                    <span>昨日</span>
                    <span>{{info1.videoYestNum}}</span>
                    <span>日环比</span>
                    <span>{{info1.rateOfVideo}}</span>
                </div>
                <div class="bottom">
                    <div class="left">总短视频数量</div>
                    <div class="right">{{info1.allVideoNum}}条</div>
                </div>
            </li>
            <!-- 圈子 -->
            <li >
                <div class="top">
                    <span>新增圈子数量</span>
                    <el-tag type="success">今日</el-tag>
                </div>
                <p class="number">{{info1.circleTodayNum}}</p>
                <div class="desc">
                    <span>昨日</span>
                    <span>{{info1.circleYestNum}}</span>
                    <span>日环比</span>
                    <span>{{info1.rateOfCircle}}</span>
                </div>
                <div class="bottom">
                    <div class="left">圈子总数量</div>
                    <div class="right">{{info1.allCircleNum}}个</div>
                </div>
            </li>
            </ul>
            <ul class="card">
                <li @click="$router.push(item.link)" v-for="item,index in tabArr" :key="index">
                    <img :src="item.img" alt="">
                    <div class="right">
                        <span class="number">{{ item.num }}</span>
                        <span class="text">{{ item.title }}</span>
                    </div>
                </li>
            </ul>
            <div class="mainBox1">
                <div class="title">
                    <span style="font-size:15px">发布市集统计</span>
                    <div class="right" style="display: flex; align-items: center;">
                        <span style="font-size:15px">时间筛选：</span>
                        <el-date-picker
      v-model="date1"
      value-format="yyyy-MM-dd"
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期">
    </el-date-picker>
                        <el-button @click="init1" style="margin-left:4px" type="primary">查询</el-button>
                    </div>
                </div>
                <div class="main1" id="main1"></div>
            </div>
            <!-- 短视频 -->
            <div class="mainBox1">
                <div class="title">
                    <span style="font-size:15px">发布短视频统计</span>
                    <div class="right" style=" display: flex; align-items: center;">
                        <span style="font-size:15px">时间筛选：</span>
                        <el-date-picker
      v-model="date2"
      value-format="yyyy-MM-dd"
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期">
    </el-date-picker>
                        <el-button  @click="init2" style="margin-left:4px" type="primary">查询</el-button>
                    </div>
                </div>
                <div class="main1" id="main2"></div>
            </div>
    </div>
</template>

<script>
import img1 from '../../../assets/images/index1.png'
import img2 from '../../../assets/images/index2.png'
import img3 from '../../../assets/images/index3.png'
import img4 from '../../../assets/images/index4.png'
import img5 from '../../../assets/images/index5.png'
import img6 from '../../../assets/images/index6.png'
import * as echarts from 'echarts';
import {option1,option2} from './init1'
import moment from 'moment';
    export default {
        data(){
            return{
                info1:{
                    todayMemberNum: "",
                    yestMemberNum: "",
                    rateOfMember: "",
                    allMemberNum: "",
                   
                    fairTodayNum: "0",
                    fairYestNum: "",
                    rateOfFair: "",
                    allFairNum: "",

                    videoTodayNum: "",
                    videoYestNum: "",
                    rateOfVideo: "",
                    allVideoNum: "",

                    circleTodayNum: "",
                    circleYestNum: "",
                    rateOfCircle: "",
                    allCircleNum: "" 
                },
                info2:{},
                tabArr:[
                    {img:img1,title:"短视频审核",num:0,link:"/video"},
                    {img:img2,title:"市集审核",num:0,link:"/fair"},
                    {img:img3,title:"门店入驻审核",num:0,link:"/merchant"},
                    {img:img4,title:"公司入驻审核",num:0,link:"/company"},
                    {img:img5,title:"圈子审核",num:0,link:"/circle"},
                    {img:img6,title:"用户管理",num:0,link:"/member"},
                ],
                date1:"",
                date2:"",
            }
        },
        created(){
    //  this.date1=[moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')]
    //  this.date2=[moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')]    
    },
        mounted(){
            this.init1()
            this.init2()
            this.gethome()
            this.getProcess()
        },
        methods:{
           async gethome(){
            let res=await this.$http.post('member/homePagePc',{})
            if(res.data.code==200){
                this.info1=res.data.data
            }else{
                this.$message.error(res.data.message)
            }
            },
            async getProcess(){
            let res=await this.$http.post('member/processStat',{})
            if(res.data.code==200){
                this.info2=res.data.data
                let {circleNum,companyNum,fairNum,memberNum,merchantNum,videoNum}=res.data.data
                let arr=[videoNum,fairNum,merchantNum,companyNum,circleNum,memberNum]
                for(let i=0;i<this.tabArr.length;i++){
                    this.tabArr[i].num=arr[i]
                }
            }else{
                this.$message.error(res.data.message)
            }
            },
         async  init1(){
            let data={startTime:"",endTime:""}
            if(Array.isArray(this.date1) && this.date1.length>0){
                data.startTime=this.date1[0]+' 00:00:00'
                data.endTime=this.date1[1] + ' 23:59:59'
            }
            let res=await this.$http.post('fair/fairPublistDay',data)
            let date=[]  //日期
            let processList=[] //审核中
            let passList=[]
            let refuseList=[]
            let allList=[]
            if(res.data.code==200){
                res.data.data.forEach(item=>{
                    date.push(item.createDt)
                    processList.push(item. processNum)
                    passList.push(item.passNum)
                    refuseList.push(item.refuseNum)
                    allList.push(item.allNum)
                })
            }
            let option=JSON.parse(JSON.stringify(option1))
            option.xAxis[0].data=date
            option.series[0].data=processList
            option.series[1].data=passList
            option.series[2].data=refuseList
            option.series[3].data=allList
            var myChart = echarts.init(document.getElementById('main1'));
            myChart.setOption(option)
        },
      async  init2(){
            let data={startTime:"",endTime:""}
            if(Array.isArray(this.date2) && this.date2.length>0){
                data.startTime=this.date1[0]+' 00:00:00' 
                data.endTime=this.date1[1] + ' 23:59:59'
            }
            let res=await this.$http.post('video/videoPublistDay',data)
            console.log(res,'pppp')
            let date=[]  //日期
            let processList=[] //审核中
            let passList=[]
            let refuseList=[]
            let allList=[]
            if(res.data.code==200){
                res.data.data.forEach(item=>{
                    date.push(item.createDt)
                    processList.push(item. processNum)
                    passList.push(item.passNum)
                    refuseList.push(item.refuseNum)
                    allList.push(item.allNum)
                })
            }
            let option=JSON.parse(JSON.stringify(option2))
            option.xAxis[0].data=date
            option.series[0].data=processList
            option.series[1].data=passList
            option.series[2].data=refuseList
            option.series[3].data=allList
            var myChart = echarts.init(document.getElementById('main2'));
            myChart.setOption(option)
        }
        }
    }
</script>

<style lang="scss" scoped>
.all{
    background: #F2F2F2;
    padding: 10px;
    height: 100%;
    .headBox{
        display: flex;
        justify-content: space-between;
        margin: 0;
        li::before{
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background: #f3f3f3;
            position: absolute;
            top: 45px;
            left: 0px;
        }
        li::after{
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background: #f3f3f3;
            position: absolute;
            bottom: 47px;
            left: 0;
        }
    li{
        position: relative;
        // width: 230px;
        width: calc((100% - 150px) / 4);
        height: 152px;
        border-radius: 2px;
        list-style: none;
        padding: 12px;
        padding-bottom: 5px;
        background: #fff;
        box-shadow: 2px 2px 8px rgba(0,0,0,.3);
        .top{
            display: flex;
            justify-content: space-between;
            position: relative;
        }
        .number{
            font-size: 24px;
            font-weight: 600;
            // margin-bottom: 20px;
            margin-top: 20px;
            margin-bottom: 7px;
        }
        .desc{
            font-size: 12px;
            color: #555;
            position: relative;
        }
        .bottom{
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            color: #555;
            margin-top: 28px;
        }
    }
}
.card{
    display: flex;
    justify-content: space-between;
    li{
        background: #fff;
        padding: 12px 16px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        width: calc((100% - 100px) / 6);
        border-radius: 2px;
        cursor: pointer;
        img{
            height: 30px;
        }
        .right{
            padding-left: 16px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .number{
                font-weight: 600;
                font-size: 23px;
            }
            .text{
                color: #555;
                font-size: 12px;
                padding-top: 5px;
            }
        }
    }
}
.mainBox1{
    background: #fff;
    .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 14px;
        border-bottom: 1px solid #f3f3f3;
    }
    .main1{
        height: 400px;
    }
}
}

</style>