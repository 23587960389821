export      let option1 = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      }
    },
  //   toolbox: {
  //     feature: {
  //       dataView: { show: true, readOnly: false },
  //       magicType: { show: true, type: ['line', 'bar'] },
  //       restore: { show: true },
  //       saveAsImage: { show: true }
  //     }
  //   },
    legend: {
      data: ['发布市集','审核中', '已通过','已拒绝' ]
    },
    xAxis: [
      {
        type: 'category',
        data: [],
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: '',
        axisLabel: {
          formatter: '{value} '
        }
      }
    ],
    series: [
      {
        name: '审核中',
        barWidth : 10,//柱图宽度
        type: 'bar',
        tooltip: {
          valueFormatter: function (value) {
            return value + ' ';
          },
          
        },
        itemStyle:{
            color:"#f59a23"
        },
        data: []
      },
      {
        name: '已通过',
        barWidth : 10,//柱图宽度
        type: 'bar',
        itemStyle:{
            color:"#63a103"
        },
        tooltip: {
          valueFormatter: function (value) {
            return value + ' ';
          }
        },
        data: [
        ]
      },
      {
        name: '已拒绝',
        barWidth : 10,//柱图宽度
        type: 'bar',
        itemStyle:{
            color:"#ec808d"
        },
        tooltip: {
          valueFormatter: function (value) {
            return value + ' ';
          }
        },
        data: [
        ]
      },
      {
        name: '发布市集',
        type: 'line',
        yAxisIndex: 0,
        itemStyle:{
            color:'#02A7F0'
        },
        tooltip: {
          valueFormatter: function (value) {
            return value + ' ';
          }
        },
        data: []
      }
    ]
  };

  export let option2={
    tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        }
      },
    //   toolbox: {
    //     feature: {
    //       dataView: { show: true, readOnly: false },
    //       magicType: { show: true, type: ['line', 'bar'] },
    //       restore: { show: true },
    //       saveAsImage: { show: true }
    //     }
    //   },
      legend: {
        data: ['发布市集','审核中', '已通过','已拒绝' ]
      },
      xAxis: [
        {
          type: 'category',
          data: [],
          axisPointer: {
            type: 'shadow'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '',
          axisLabel: {
            formatter: '{value} '
          }
        }
      ],
      series: [
        {
          name: '审核中',
          barWidth : 10,//柱图宽度
          type: 'bar',
          tooltip: {
            valueFormatter: function (value) {
              return value + ' ';
            },
            
          },
          itemStyle:{
              color:"#f59a23"
          },
          data: [
            
          ]
        },
        {
          name: '已通过',
          barWidth : 10,//柱图宽度
          type: 'bar',
          itemStyle:{
              color:"#63a103"
          },
          tooltip: {
            valueFormatter: function (value) {
              return value + ' ';
            }
          },
          data: [
           
          ]
        },
        {
          name: '已拒绝',
          barWidth : 10,//柱图宽度
          type: 'bar',
          itemStyle:{
              color:"#ec808d"
          },
          tooltip: {
            valueFormatter: function (value) {
              return value + ' ';
            }
          },
          data: [
           
          ]
        },
        {
          name: '发布市集',
          type: 'line',
          yAxisIndex: 0,
          itemStyle:{
              color:'#02A7F0'
          },
          tooltip: {
            valueFormatter: function (value) {
              return value + ' ';
            }
          },
          data: []
        }
      ]
  }